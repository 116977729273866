import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Input, Modal, Radio, RadioChangeEvent, Select, Space, Spin, Table } from "antd";
import { CheckOutlined, CloseOutlined, PlusCircleOutlined } from "@ant-design/icons";
import "./TaxonomyTree.scss";
import { RootState } from "../../../store";
import TreeDescription from "./TreeDescription";
import {
  IMAGES,
  generateRandomNumber,
  TaxonomyItem,
  TaxonomyDeleteOptions,
  useDebounce,
} from "../../../shared";
import {
  createNewTaxonomyNode,
  deleteTaxonomyNodes,
  fetchTaxonomyById,
  getTaxonomyNodesBySearch,
  moveAllChildToParent,
  updateTaxonomyNode,
} from "../../../services/taxonomy";

interface IFrom {
  colLevel: number;
}
// by nodeId finds node and returns its object
const findNode = (nodes: TaxonomyItem[], selectedNodeId: number): TaxonomyItem | null => {
  for (const node of nodes) {
    if (node.taxonomyNodeId === selectedNodeId) {
      return node; // Return the current node
    }
    if (node?.children?.length > 0) {
      const found = findNode(node.children, selectedNodeId);
      if (found) return found;
    }
  }
  return null;
};

// remove nodes from taxonomy
const removeNodesRecursively = (
  nodes: TaxonomyItem[],
  nodeIds: React.Key[] | number[]
): TaxonomyItem[] => {
  return nodes.reduce((acc, node) => {
    const updatedChildren = removeNodesRecursively(node.children, nodeIds);
    if (!nodeIds.includes(node.taxonomyNodeId)) {
      acc.push({ ...node, children: updatedChildren });
    }
    return acc;
  }, [] as TaxonomyItem[]);
};

// Update the state with the modified taxonomy
const updateNodeInTaxonomy = (
  taxonomy: TaxonomyItem[],
  updatedNode: TaxonomyItem
): TaxonomyItem[] => {
  return taxonomy.map((node) =>
    node.taxonomyNodeId === updatedNode.taxonomyNodeId
      ? updatedNode
      : { ...node, children: updateNodeInTaxonomy(node.children, updatedNode) }
  );
};

function TaxonomyTree({ colLevel }: IFrom) {
  const [maxLevel, setMaxLevel] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(-1);
  const [editableNodeId, setEditableNodeId] = useState(-1);
  const [editedNodeValue, setEditedNodeValue] = useState("");
  const [isNewChildNodeId, setNewChildeNodeId] = useState(-1);
  const [taxonomy, setTaxonomy] = useState<TaxonomyItem[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [replaceNodeText, setReplaceNodeText] = useState<string>("");
  const [selectedRowIds, setSelectedRowIds] = useState<React.Key[]>([]);
  const [parentNodeData, setParentNodeData] = useState<number | null>();
  const [deleteOptionValue, setDeleteOptionValue] = useState(
    TaxonomyDeleteOptions.MoveAllChildToParent
  );

  const { id } = useParams();
  const taxonomyId: number = Number(id) > 0 ? Number(id) : -1;
  const [searchText, setSearchText] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { getTreeTableLoading, getTaxonomyTree, deleteTreeTableLoading } = useSelector(
    (state: RootState) => state.taxonomy
  );

  useEffect(() => {
    if (taxonomyId) {
      fetchTaxonomyById(taxonomyId);
    }
  }, [taxonomyId]);

  useEffect(() => {
    setMaxLevel(colLevel);
  }, [colLevel]);

  useEffect(() => {
    getTaxonomyTree?.taxonomyTree && setTaxonomy(getTaxonomyTree?.taxonomyTree);
  }, [getTaxonomyTree?.taxonomyTree]);

  const resetDeleteModal = () => {
    setIsDeleteModalOpen(false),
      setDeleteOptionValue(TaxonomyDeleteOptions.MoveAllChildToParent),
      setReplaceNodeText("");
  };

  const handleCloseNodeInput = (node: TaxonomyItem) => {
    setEditableNodeId(-1);
    setEditedNodeValue("");
    setNewChildeNodeId(-1);

    if (node?.taxonomyNodeName === "") {
      const updatedNodes = removeNodesRecursively(taxonomy, [node?.taxonomyNodeId]);
      setTaxonomy(updatedNodes);
    }
  };

  const handleChangeTaxonomyLevels = (value: string) => {
    setMaxLevel(Number(value));
  };

  const handleRemoveChildren = async (nodeId: number) => {
    await deleteTaxonomyNodes([nodeId]);

    const updatedNodes = removeNodesRecursively(taxonomy, [nodeId]);
    setTaxonomy(updatedNodes);
  };

  const handleReplaceNodeText = async () => {
    const taxonomyNodeId: number = parseInt(selectedRowIds[0].toString());

    const selectedNode = findNode(taxonomy, Number(selectedRowIds[0]));

    await updateTaxonomyNode(
      taxonomyNodeId,
      false,
      {
        description: selectedNode?.description,
        taxonomyNodeName: replaceNodeText,
      },
      taxonomy
    );

    const updateNode = (nodes: TaxonomyItem[]): TaxonomyItem[] => {
      return nodes.map((node) => {
        if (node.taxonomyNodeId === taxonomyNodeId) {
          return {
            ...node,
            taxonomyNodeName: replaceNodeText,
          };
        }
        if (node.children.length > 0) {
          return { ...node, children: updateNode(node.children) };
        }
        return node;
      });
    };

    setTaxonomy(updateNode(taxonomy));
  };

  const handleSubmitDeleteModal = async () => {
    if (selectedRowIds?.length > 1) {
      await deleteTaxonomyNodes(selectedRowIds);

      const updatedNodes = removeNodesRecursively(taxonomy, selectedRowIds);
      setTaxonomy(updatedNodes);
      resetDeleteModal();
    } else {
      const selectedId: number = parseInt(selectedRowIds[0].toString());

      resetDeleteModal();
      switch (deleteOptionValue) {
        case TaxonomyDeleteOptions.MoveAllChildToParent:
        default:
          return moveAllChildToParent(selectedId, taxonomyId);
        case TaxonomyDeleteOptions.DeleteAllChildren:
          return handleRemoveChildren(selectedId);
        case TaxonomyDeleteOptions.ReplaceText:
          return handleReplaceNodeText();
      }
    }
  };

  const handleDeleteOptionsChange = (e: RadioChangeEvent) => {
    const selectedNode = findNode(taxonomy, Number(selectedRowIds[0]));
    selectedNode?.taxonomyNodeName && setEditedNodeValue(selectedNode?.taxonomyNodeName);

    setDeleteOptionValue(e.target.value);
  };

  const handleSaveNode = (nodeId: number, newName: string) => {
    const resetInput = () => {
      setEditableNodeId(-1);
      setNewChildeNodeId(-1);
      setParentNodeData(null);
      setEditedNodeValue("");
    };

    const updateNode = (nodes: TaxonomyItem[]) => {
      nodes.forEach((node) => {
        if (node.taxonomyNodeId === nodeId) {
          if ((parentNodeData === null || undefined) && isNewChildNodeId !== -1) {
            const body = {
              taxonomyNodeName: newName,
              description: "",
              parentTaxonomyNodeId: null,
              taxonomyId: taxonomyId,
              taxonomyNodeLevel: 0,
            };

            createNewTaxonomyNode(body, node?.taxonomyNodeId, taxonomy, setTaxonomy, resetInput);
          } else {
            if (isNewChildNodeId !== -1) {
              const body = {
                taxonomyNodeName: newName,
                description: "",
                parentTaxonomyNodeId: parentNodeData,
                taxonomyId: taxonomyId,
                taxonomyNodeLevel: 0,
              };

              createNewTaxonomyNode(body, node?.taxonomyNodeId, taxonomy, setTaxonomy, resetInput);
            } else {
              updateTaxonomyNode(
                node.taxonomyNodeId,
                true,
                {
                  description: node.description,
                  taxonomyNodeName: newName,
                },
                taxonomy,
                setTaxonomy,
                resetInput
              );
            }
          }
          return { ...node, taxonomyNodeName: newName };
        }

        if (node?.children?.length > 0) {
          return { ...node, children: updateNode(node.children) };
        }

        return node;
      });
    };
    updateNode(taxonomy);
    // setTaxonomy(updateNode(taxonomy));
  };

  const handleAddNode = (parentId: number) => {
    // Recursively find the parent node in the taxonomy tree
    const findParentNode = (nodes: TaxonomyItem[]): TaxonomyItem | null => {
      for (const node of nodes) {
        if (node.taxonomyNodeId === parentId) {
          return node;
        }
        if (node.children.length > 0) {
          const found = findParentNode(node.children);

          if (found) return found;
        }
      }

      return null;
    };

    // Find the parent node
    const parentNode = findParentNode(taxonomy);

    if (parentNode) {
      // Create a new node
      const newNode: TaxonomyItem = {
        taxonomyNodeId: generateRandomNumber(),
        taxonomyNodeName: ``,
        description: "",
        linkedAsset: [],
        children: [],
      };

      setEditableNodeId(newNode?.taxonomyNodeId);
      setEditedNodeValue(newNode?.taxonomyNodeName);
      setNewChildeNodeId(1);
      setParentNodeData(parentNode.taxonomyNodeId);

      // Create a new parent node with the updated children array
      const updatedParentNode: TaxonomyItem = {
        ...parentNode,
        children: [...parentNode.children, newNode],
      };

      // Update the state with the modified taxonomy
      const updatedTaxonomy = updateNodeInTaxonomy(taxonomy, updatedParentNode);
      setTaxonomy(updatedTaxonomy);
    } else {
      console.error("Parent node not found");
    }
  };

  const generateDataSource = (): TaxonomyItem[] => {
    const dataSource: TaxonomyItem[] = [];
    let count = 1;
    const traverse = (nodes: TaxonomyItem[], level: number) => {
      nodes.forEach((node) => {
        const row: any = {};
        const isMatching =
          searchText && node.taxonomyNodeName.toLowerCase().includes(searchText.toLowerCase());

        row[`taxonomyNodeId`] = node.taxonomyNodeId; // Add taxonomyNodeId field
        row[`no`] = count++;
        row[`level_${level}`] = (
          <span className="edit-table-input">
            {editableNodeId === node.taxonomyNodeId ? (
              <Input
                value={editedNodeValue}
                autoFocus
                onChange={(e) => {
                  setEditedNodeValue(e.target.value);
                }}
                onPressEnter={() => {
                  editedNodeValue !== "" && handleSaveNode(node.taxonomyNodeId, editedNodeValue);
                }}
                suffix={
                  <>
                    <Button
                      type="link"
                      icon={<CheckOutlined style={{ fontSize: "13px" }} />}
                      disabled={editedNodeValue === ""}
                      className={editedNodeValue === "" ? "disableDescriptionCursor" : ""}
                      onClick={() => {
                        handleSaveNode(node.taxonomyNodeId, editedNodeValue);
                      }}
                    />
                    <Button
                      type="link"
                      icon={<CloseOutlined style={{ fontSize: "13px" }} />}
                      onClick={() => handleCloseNodeInput(node)}
                    />
                  </>
                }
              />
            ) : (
              <span className="title-level-inner acn-flex acn-flex-middle">
                <span
                  className="title-level"
                  onClick={() => {
                    editableNodeId === -1 &&
                      (setEditableNodeId(node.taxonomyNodeId),
                      setEditedNodeValue(node.taxonomyNodeName));
                  }}>
                  {node.taxonomyNodeName}
                </span>
                {editableNodeId === -1 && level < maxLevel && (
                  <Button
                    size="small"
                    onClick={() => {
                      handleAddNode(node.taxonomyNodeId);
                    }}
                    icon={<img src={IMAGES.plusIcon} />}
                    className="plus-icon"
                    type="link"
                  />
                )}
              </span>
            )}
          </span>
        );
        row["description"] = (
          <div className="tree-discription acn-flex">
            <TreeDescription
              isOpen={node.taxonomyNodeId === isModalOpen}
              setModalOpen={setIsModalOpen}
              nodeId={node?.taxonomyNodeId}
              setTaxonomy={setTaxonomy}
              taxonomy={taxonomy}
              description={node?.description}
              disablePopover={editableNodeId !== -1}
            />
            {node?.description !== "" && node?.description?.length > 15 && (
              <u className="more-tag" onClick={() => setIsModalOpen(node?.taxonomyNodeId)}>
                +more
              </u>
            )}
          </div>
        );

        row["linkedAsset"] = node?.linkedAsset?.length;
        dataSource.push({ ...row, isMatching });

        if (node?.children && node?.children.length > 0) {
          traverse(node.children, level + 1);
        }
      });
    };

    traverse(taxonomy, 0);

    // Add a row with an "Add" button at level 0
    const lastRow: any = {};
    lastRow[`taxonomyNodeId`] = generateRandomNumber(); // Add taxonomyNodeId field
    lastRow[`level_0`] = (
      <span>
        <Button
          size="small"
          disabled={editableNodeId !== -1}
          onClick={() => {
            const newNode: TaxonomyItem = {
              taxonomyNodeId: generateRandomNumber(),
              taxonomyNodeName: ``,
              description: "",
              linkedAsset: [],
              children: [],
            };
            setTaxonomy([...taxonomy, newNode]);
            setEditableNodeId(newNode.taxonomyNodeId);
            setNewChildeNodeId(1);
            setEditedNodeValue(newNode.taxonomyNodeName);
          }}
          icon={<img src={IMAGES.plusIcon} />}
          className="plus-icon plus-icon-fill"
          type="link"
        />
      </span>
    );
    lastRow["description"] = "";
    lastRow["linkedAsset"] = "";
    dataSource.push(lastRow);

    return dataSource;
  };

  const columns = [
    { title: "No.", dataIndex: "no", key: "no" },
    ...Array.from({ length: maxLevel + 1 }, (_, i) => ({
      title: `Level ${i}`,
      dataIndex: `level_${i}`,
      key: `level_${i}`,
    })),
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "No. of linked assets", dataIndex: "linkedAsset", key: "linkedAsset" },
  ];

  const dataSource = generateDataSource();

  const getTaxonomyBySearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEdit(true);
    const trimmedSearchText = e.target.value.trim();

    if (trimmedSearchText.length >= 3)
      await getTaxonomyNodesBySearch(taxonomyId, trimmedSearchText);
    if (e.target.value.length === 0) {
      await fetchTaxonomyById(taxonomyId);
    }
    setSearchText(trimmedSearchText);
  };

  const handleSearchDebounce = useDebounce(getTaxonomyBySearch, 500);

  const rowClassName = (record: any) => {
    return record.isMatching ? "matching-row" : "";
  };

  return (
    <div className="tree-hierarchy-new">
      <div className={`container-taxonomy ${getTreeTableLoading && "layer"}`}>
        <div
          className={
            selectedRowIds?.length > 0
              ? "header-taxonomy acn-flex acn-flex-middle acn-flex-between"
              : "header-taxonomy acn-flex acn-flex-middle acn-flex-right"
          }>
          <div hidden={colLevel > 0 ? true : false} className="add-level add-taxonomy">
            <Button type="text" className="add">
              <PlusCircleOutlined /> Add taxonomy
            </Button>
          </div>

          {selectedRowIds?.length > 0 && taxonomy.length > 0 && (
            <Button
              className="balk-delete"
              disabled={!selectedRowIds?.length}
              onClick={() => setIsDeleteModalOpen(true)}>
              <img src={IMAGES.deleteIcon} alt="delete" /> Delete
            </Button>
          )}
          {(dataSource?.length > 1 || isEdit) && (
            <div className="two-action acn-flex acn-flex-middle">
              <div className="search-form">
                <Input
                  className="search-field"
                  placeholder="Search terms.."
                  onChange={handleSearchDebounce}
                  allowClear
                  prefix={<img src={IMAGES.searchIcon} alt="search" />}
                />
              </div>
            </div>
          )}

          <div hidden={colLevel > 0 ? true : false} className="add-level level-select">
            <Select
              placeholder="Select"
              disabled={taxonomy.length > 0}
              style={{ width: 120 }}
              value={maxLevel >= 0 ? maxLevel.toString() : null}
              onChange={handleChangeTaxonomyLevels}
              options={[
                { value: "0", label: "Level 0" },
                { value: "1", label: "Level 1" },
                { value: "2", label: "Level 2" },
                { value: "3", label: "Level 3" },
                { value: "4", label: "Level 4" },
                { value: "5", label: "Level 5" },
                { value: "6", label: "Level 6" },
              ]}
            />
          </div>
        </div>
        {getTreeTableLoading && (
          <div className="loader-tree">
            <Spin />
          </div>
        )}
        <div className="table-ui">
          {!!(maxLevel >= 0) && (
            <Table
              className="tree-table-ui"
              rowKey={"taxonomyNodeId"}
              rowSelection={{
                type: "checkbox",
                onChange: (selectedRowKeys: React.Key[]) => {
                  setSelectedRowIds(selectedRowKeys);
                },
                getCheckboxProps: (record: any) => ({
                  disabled:
                    record.taxonomyNodeId === dataSource[dataSource.length - 1].taxonomyNodeId,
                  style: {
                    display:
                      record.taxonomyNodeId === dataSource[dataSource.length - 1].taxonomyNodeId
                        ? "none"
                        : "",
                  },
                  name: record.name,
                }),
              }}
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              rowClassName={rowClassName} // Set the rowClassName callback
              tableLayout="fixed"
              scroll={{ y: "calc(100vh - 188px)", x: "100" }}
            />
          )}
        </div>
      </div>

      <Modal
        className="delete-confirmation-modal"
        centered
        open={isDeleteModalOpen}
        onOk={handleSubmitDeleteModal}
        onCancel={resetDeleteModal}
        confirmLoading={deleteTreeTableLoading}
        cancelButtonProps={{ style: { display: "none" } }}
        footer={[
          <>
            <Button key="button" type="default" onClick={resetDeleteModal}>
              Cancel
            </Button>

            <Button
              key="submit"
              type="primary"
              onClick={handleSubmitDeleteModal}
              loading={deleteTreeTableLoading}
              disabled={
                deleteOptionValue === TaxonomyDeleteOptions.ReplaceText && replaceNodeText === ""
              }>
              Delete
            </Button>
          </>,
        ]}>
        <div className="inner-modal-delete">
          {selectedRowIds?.length > 1 && <h3>Are you sure you want to delete this taxonomy?</h3>}
          {selectedRowIds?.length > 1 && (
            <p>
              This action will permanently delete the selected row and cannot be undone. Following
              this deletion, the remaining taxonomies will be repositioned upward in the sequence
            </p>
          )}
          {selectedRowIds?.length === 1 && (
            <>
              <h3>Are you sure you want to delete?</h3>
              <p>
                Deleting this term will lead to <strong>tagging change</strong> on all the linked
                assets. Please choose an option below to proceed.
              </p>

              <Radio.Group
                className="delete-radio-group"
                onChange={handleDeleteOptionsChange}
                value={deleteOptionValue}
                disabled={deleteTreeTableLoading}>
                <Space direction="vertical">
                  <Radio value={TaxonomyDeleteOptions.MoveAllChildToParent}>
                    Move all children to parent
                  </Radio>
                  <Radio value={TaxonomyDeleteOptions.DeleteAllChildren}>
                    Delete row including children
                  </Radio>
                  <Radio value={TaxonomyDeleteOptions.ReplaceText}>Replace with another text</Radio>
                  {deleteOptionValue === TaxonomyDeleteOptions.ReplaceText ? (
                    <div className="tree-replace-text-list">
                      <ul>
                        <li className="acn-flex acn-flex-middle replace-text-item">
                          <span className="view-label">{editedNodeValue}</span>
                          <text className="to">to</text>
                          <span className="edit-label">
                            <Input
                              placeholder="Type here..."
                              value={replaceNodeText}
                              onChange={(e) => {
                                setReplaceNodeText(e.target.value);
                              }}
                            />
                          </span>
                        </li>
                      </ul>
                    </div>
                  ) : null}
                </Space>
              </Radio.Group>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default TaxonomyTree;
